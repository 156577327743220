import React, {useContext, useEffect, useRef, useState} from 'react';
import MovePage from "./MovePage";
import FlashCard from "./FlashCard";

const Word = () => {
    const [words, setWords] = useState([]);
    const [changedWords, setChangedWords] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const incompleteWords = words.filter(word => word.state == 0); // 학습되지 않은 단어
    const moveRef = useRef(null);
    const cardRef = useRef(null);
    const [isFlipped, setIsFlipped] = useState(false);
    const [isFlashcardVisible, setIsFlashcardVisible] = useState(true);

    const fetchWordsFromServer = async () => {
        try {
            const response = await fetch('/api/word/getWords', {
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error(`서버 에러: ${response.status}`);
            }
            const result = await response.json();
            const sortedWords = result.sort((a, b) => a.id - b.id);
            setWords(sortedWords);
        } catch (error) {
            console.error('데이터 가져오기 실패:', error);
        }
    }

    useEffect(() => {
        fetchWordsFromServer();
    }, []);

    const handleKnow = () => {
        const currentWordId = incompleteWords[currentIndex].id;

        const updatedWords = words.map(word =>
            word.id === currentWordId ? { ...word, state: 1 } : word
        );

        setWords(updatedWords);
        handleWordChange(updatedWords, currentWordId);
    };

    const handleDontKnow = () => {
        const currentWordId = incompleteWords[currentIndex].id;

        const updatedWords = words.map(word =>
            word.id === currentWordId ? { ...word, incorrect_count: word.incorrect_count + 1 } : word
        );

        setWords(updatedWords);
        handleWordChange(updatedWords, currentWordId);

        setCurrentIndex((prevIndex) => (prevIndex + 1) % incompleteWords.length);
    };

    // 단어 변경사항 추적
    const handleWordChange = (updatedWords, currentWordId) => {
        const alreadyChanged = changedWords.find((word) => word.id === currentWordId);
        const updatedWord = updatedWords.find((word) => word.id === currentWordId);

        if (updatedWord) {
            if (!alreadyChanged) {
                // 변경된 단어가 없으면 추가
                setChangedWords(prev => [...prev, { ...updatedWord }]);
            } else {
                // 이미 변경된 항목이 있으면 업데이트
                setChangedWords(prev =>
                    prev.map(word => word.id === currentWordId ? { ...updatedWord } : word)
                );
            }
        }
    };

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    const handleSave = () => {
        updateWordsToServer(words);
    };

    const handleBack = () => {
        if (moveRef.current) {
            moveRef.current.click();
        }
    }

    const handleCopy = () => {
        const wordToCopy = !isFlipped ? incompleteWords[currentIndex].word : incompleteWords[currentIndex].meaning;
        navigator.clipboard.writeText(wordToCopy).then(() => {
            console.log('복사 성공:', wordToCopy);
            // alert(`단어 '${wordToCopy}'가 클립보드에 복사되었습니다.`);
        }).catch(err => {
            console.error('복사 실패:', err);
        });
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === '1' || e.key === 'w' || e.key === 'W') { // 1, w 눌러 안다 선택
                handleKnow();
            } else if (e.key === '2' || e.key === 'e' || e.key === 'E') { // 2, e 눌러 모른다 선택
                handleDontKnow();
            } else if (e.key === 'q' || e.key === 'Q' || e.key === ' ') { // q, 스페이스로 카드 뒤집기
                handleFlip();
            } else if (e.key === 's' || e.key === 'S' ) {
                handleSave(); // s 눌러 저장
            } else if (e.key === 'b' || e.key === 'B') {
                handleBack(); // b 눌러 저장
            } else if (e.key === 'd' || e.key === 'D') {
                setIsFlashcardVisible(false) // d 눌러 flashcard 숨기기
            } else if (e.key === 'f' || e.key === 'F') {
                setIsFlashcardVisible(true); // f 눌러 flashcard 보이게 하기
            } else if (e.key === 'c' || e.key === 'C') {
                handleCopy(true); // c 눌러 flashcard 텍스트 복사
            }
        };

        // 키보드 이벤트 리스너 등록
        window.addEventListener('keydown', handleKeyDown);

        // 컴포넌트 언마운트 시 이벤트 리스너 해제
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKnow, handleDontKnow, handleFlip, handleSave, handleBack]);

    if(!words || words.length === 0){
        return <div className="loading">Loading...</div>;
    }

    if (incompleteWords.length === 0) {
        return <div>모든 단어를 외웠습니다!</div>;
    }

    // 변경된 값 저장
    const updateWordsToServer = async updatedWords => {
        if (changedWords.length > 0) {
            try {
                const response = await fetch('/api/word/updateWords', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(changedWords),  // 변경된 단어들만 서버로 전송
                });

                if (!response.ok) {
                    throw new Error(`서버 에러: ${response.status}`);
                }

                const result = await response.json();
                alert('저장이 완료되었습니다.');
                console.log('저장 성공:', result)
                setChangedWords([]); // 변경 단어 초기화
            } catch (error) {
                alert('저장이 실패하였습니다.');
                console.error('저장 실패:', error);
            }
        } else {
            alert('변경된 데이터가 없습니다.');
            console.log('변경된 데이터가 없습니다.');
        }
    };

    return (
        <div className="words">
            <div className="top-button-container">
                <button onClick={handleSave} className="save-button">저장</button>
                <MovePage ref={moveRef} path={'/'} text={'돌아가기'} classNm={'move-main-button'}/>
            </div>
            <div className="cards">
                <FlashCard ref={cardRef} isVisible={isFlashcardVisible} front={incompleteWords[currentIndex].word} back={incompleteWords[currentIndex].meaning}
                           currentIndex={currentIndex} isFlipped={isFlipped} onClick={handleFlip}/>
            </div>
            <div className="bottom-button-container">
                <button onClick={handleKnow} className="know-button">안다</button>
                <button onClick={handleDontKnow} className="dont-know-button">모른다</button>
            </div>
        </div>
    )
}

export default Word;