// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 120px;
    padding: 20px;
    height: 100vh;
}

.app .design-mode {
    margin-top: 60px;
    padding: 10px;
    text-align: center;
}

.app.ddark .design-title {
    color: white;
}

.app .design-mode .select-box {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    color: #333;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin-top: 20px;
    appearance: none; /* 기본 브라우저 스타일 제거 */
    outline: none; /* 선택 시 아웃라인 제거 */
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.select-box option {
    background-color: white; /* 드롭다운 배경 */
    color: #333;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,WAAW;IACX,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB,EAAE,mBAAmB;IACrC,aAAa,EAAE,iBAAiB;IAChC,eAAe;IACf,kCAAkC;AACtC;;AAEA;IACI,uBAAuB,EAAE,YAAY;IACrC,WAAW;AACf","sourcesContent":[".app {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 120px;\n    padding: 20px;\n    height: 100vh;\n}\n\n.app .design-mode {\n    margin-top: 60px;\n    padding: 10px;\n    text-align: center;\n}\n\n.app.ddark .design-title {\n    color: white;\n}\n\n.app .design-mode .select-box {\n    width: 200px;\n    padding: 10px;\n    font-size: 16px;\n    color: #333;\n    background-color: #f0f0f0;\n    border: 2px solid #ccc;\n    border-radius: 5px;\n    margin-top: 20px;\n    appearance: none; /* 기본 브라우저 스타일 제거 */\n    outline: none; /* 선택 시 아웃라인 제거 */\n    cursor: pointer;\n    transition: border-color 0.3s ease;\n}\n\n.select-box option {\n    background-color: white; /* 드롭다운 배경 */\n    color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
