import React from 'react';
import { useNavigate } from 'react-router-dom';

function LogoutButton({ classNm }) {
    const navigate = useNavigate();

    const handleLogout = () => {
        fetch('/logout', {
            method: 'POST',
            credentials: 'include',
        })
            .then(response => {
                if (response.ok) {
                    navigate('/');
                } else {
                    alert('로그아웃에 실패하였습니다.');
                }
            })
            .catch(error => {
                alert('로그아웃에 실패하였습니다.');
            });
    };

    return (
        <button className={classNm} onClick={handleLogout}>
            로그아웃
        </button>
    );
}

export default LogoutButton;
