import React, {forwardRef} from 'react';

const FlashCard = forwardRef(({ front, back, currentIndex, isFlipped, isVisible, onClick }, ref) => {
    return (
        <div ref={ref} className="flashcard" onClick={onClick} style={{display: isVisible ? '' : 'none'}}>
            <div className="index">{currentIndex + 1}</div>
            {isFlipped ? <div className="back">{back}</div> : <div className="front">{front}</div>}
        </div>
    );
});

export default FlashCard;