import React, {useEffect, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import Main from "./Main";
import Word from "./Word";
import Login from "./Login";
import Kanji from "./Kanji";
import Register from "./Register";

const AppContent = () => {
    const [mode, setMode] = useState('ddark');
    const location = useLocation(); // 현재 경로를 확인

    useEffect(() => {
        const savedMode = localStorage.getItem('designMode');
        if (savedMode) {
            setMode(savedMode);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('designMode', mode);
    }, [mode]);

    const handleModeChange = (event) => {
        setMode(event.target.value);
    };

    return (
        <div className={`app ${mode}`}>
            <div className="design-mode" style={{display: location.pathname === '/' ? 'block' : 'none'}}>
                <h1 className="design-title">디자인 설정: {mode.toUpperCase()}</h1>
                <select className="select-box" value={mode} onChange={handleModeChange}>
                    <option value="ddark">DDARK</option>
                    <option value="light">LIGHT</option>
                    {/*<option value="dark">DARK</option>*/}
                </select>
            </div>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/word" element={<Word/>}/>
                <Route path="/kanji" element={<Kanji/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>
            </Routes>
        </div>
    );
}

export default AppContent;