import React from 'react';
import AppContent from './component/AppContent'
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';

const App = () => {
    return (
        <Router>
            <AppContent />
        </Router>
    )
};

export default App;