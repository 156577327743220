import React from 'react';
import MovePage from './MovePage'
import LogoutButton from './LogoutButton';

const Main = () => {
    return (
        <div className="main">
            <div className="fixed-top-right">
                <div className="login-box">
                    <MovePage path={'/login'} text={'로그인'} classNm={'login-button'}/>
                    <LogoutButton classNm={'logout-button'} />
                    <MovePage path={'/register'} text={'회원가입'} classNm={'register-button'}/>
                </div>
            </div>
            <div className="contents-box">
                <MovePage path={'/word'} text={'단어'} classNm={'menu-button'}/>
                {/*<MovePage path={'/kanji'} text={'문장'} classNm={'menu-button'}/>*/}
                <MovePage path={'/kanji'} text={'한자'} classNm={'menu-button'}/>
            </div>
        </div>
    )
}

export default Main;